// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  z-index: 9999;
}
.loading-container .modal-content {
  background-color: unset !important;
  box-shadow: unset !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
}

.loading-container ~ .modal-backdrop {
  z-index: 9998;
}`, "",{"version":3,"sources":["webpack://./src/scss/_loading.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;AAAE;EACE,kCAAA;EACA,4BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AAEJ;;AAEA;EACE,aAAA;AACF","sourcesContent":[".loading-container {\n  z-index: 9999;\n  .modal-content {\n    background-color: unset !important;\n    box-shadow: unset !important;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: unset;\n  }\n}\n\n.loading-container ~ .modal-backdrop {\n  z-index: 9998;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
