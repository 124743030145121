import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LINK_URL } from 'src/utils/constants'
import { Auth } from 'src/helper/Auth'
import { CONTACT_LINK } from 'src/utils/constants'

export function ListLink() {
  const isLogin = Auth.getToken()
  const { t } = useTranslation()
  return (
    <div className="d-flex justify-content-end hidden-on-mobile">
      <a target="_blank" href={LINK_URL.HEADER.URL_1} rel="noreferrer" className="header-text-list">
        {t('footer.usage_guide')}
      </a>
      <a target="_blank" href={LINK_URL.HEADER.URL_2} rel="noreferrer" className="header-text-list">
        {t('footer.faq')}
      </a>
      {isLogin && (
        <Link to={CONTACT_LINK} className="header-text-list">
          {t('footer.inquiry_form')}
        </Link>
      )}
    </div>
  )
}
