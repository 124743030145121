const ja = {
  messages: {
    no_article: ' 記事がありません。',
    no_notification: '通知がありません。',
  },
  common: {
    email: 'メールアドレス',
    email_confirm: 'メールアドレス（確認用）',
    password: 'パスワード',
    contract_number: '検針票番号',
    confirm_password: ' パスワード（確認用）',
    no_record: '通知がありません。',
    placeholder_password: '● ● ● ● ● ● ● ●',
    search: '検索',
  },
  sideBar: {
    top: 'TOP',
    energySaving: '省エネ',
    notification: '通知',
    myPage: 'マイページ',
    security_safety: '保安・安全',
    profile_settings: 'プロフィール設定',
    sendMail: 'SendMail',
    confirm_contract: '契約情報',
    notification_settings: '通知設定の変更',
  },
  tabs: {
    important: '重要',
    general: '一般',
  },
  pageTitles: {
    notification: 'お知らせ一覧',
    energySaving: '省エネ',
    energySavingContent: '冬のエアコン編',
    faq: 'よくあるご質問',
    usage_guide: '利用ガイド',
    contact_list: '問い合わせ先一覧',
    form_qa: 'お問い合わせフォーム',
  },
  notification: {
    important_flag: '【重要】',
  },
  footer: {
    address: '〒160-0023 東京都新宿区西新宿 8-14-24 西新宿 KF ビル 2F',
    phone_number: 'TEL : 03-6451-0270',
    usage_guide: '利用ガイド',
    first_time_registration_guide: '初回登録ガイド',
    login_guide: 'ログインガイド',
    faq: 'FAQ',
    inquiry_form: '申込・問合せ',
    term: '利用規約',
    policy: 'プライバシーポリシー',
  },
  top: {
    gas_note_9: 'LPガスの販売に関わる諸経費',
    gas_note_8: 'LPガスの配送費',
    gas_note_7: 'LPガスの原料費',
    gas_note_6: '※ 使用量（1㎥ごと）に応じて発生する費用です。',
    gas_note_5: '法定点検調査費用等の保安管理費など',
    gas_note_4: '供給設備配管減価',
    gas_note_3: '※ 基本料金は固定的な費用として使用量にかかわらず発生する費用です。',
    gas_note_2: '基本料金＋従量料金（＋設備使用料）',
    gas_note_1: '基本料金＋従量料金（＋設備使用料）',
    gas_note_0: 'LPガス料金の算定方法は以下の通りです。',
    about_gas_charge: 'LPガスの料金について',
    basic_charge: '基本料金（税抜）',
    volume_charge: '従量料金（円/㎥）',
    gas_unit_price: 'お客様のLPガス料金単価',
    last_meter_reading_date: '前回検針日',
    meter_reading_result: '検針結果',
    total: '合計',
    subtotal_breakdown: '小計内訳',
    tax_message_1: '明細の品目にある「',
    tax_message_2: '」は8％軽減税率対象です。',
    consumption_tax: '内消費税',
    date: '日付',
    unit_price_including_tax: '税込単価',
    carry_forward_balance: '繰越残高',
    payment_include_tax: '今回ご利用額(税込)',
    payment_bill_amount: '今回ご入金額',
    current_month_bill_amount: '当月請求額',
    previous_bill_amount: '前回ご請求額',
    payment_method: 'お支払方法',
    billing_date: '請求日',
    dear: '様',
    registration_number: '登録番号',
    previous_month: '前月',
    next_month: '翌月',
    there_is_no_applicable_data: '当該の請求がありません',
    bill_for_current_month: '当月請求額',
    billing_amount: '当月請求額',
    billing_amount_top: '請求予定額',
    tax_included: '（税込）',
    check_your_statement: '明細を確認する',
    check_with_graph: 'グラフで確認する',
    confirm_contract_info: '契約情報を確認する',
    see_the_list: '一覧を見る ',
    you_have_unread_notification: '件の未読通知があります。',
    no_unread_notification: '未読通知がありません。',
    billing_statement: '当月ご利用明細',
    detail_tab_title: '請求明細',
    graph_tab_title: '使用量表示',
    claim_number: '請求番号 ',
    confirm_contact_detail: 'ご契約内容を確認',
    amount_including_tax: '税込金額',
    cosumption_tax: '内消費税',
    target_total_1: '8%対象計',
    target_total_2: '8%(軽減)対象計',
    target_total_3: '10%対象計',
    exempted_total: '非課対象計',
    previous_balance: '前回残高 ',
    deposit_amount: '入金額 ',
    carry_over_amount: '繰越金額 ',
    current_balance: '今回請求額（今回残高）',
    gas_usage_detail: 'ガスご利用明細',
    check_meter_reading_slip: '検針票を確認 ',
    no_gas_available: 'ガスのご利用がありません。',
    no_electric_available: '電気のご利用がありません。',
    no_others_bill_available: 'その他のご利用がありません。',
    no_chart_gas_available: 'ガスのご利用がありません。',
    no_chart_electric_available: '電気のご利用はありません。',
    product_name: '品名',
    quantify: '数量',
    tax_excluded_amount: '税込金額',
    tax_rate: '税率',
    electric_bill: '電気ご利用明細',
    others_bill: 'その他ご利用明細',
    gas_meter: 'LPガス検針票',
    electric_meter: '電気検針票',
    supply_id: '供給地点特定番号',
    current_meter_reading_date: '今回検針日',
    previous_meter_reading_date: '前回検針日',
    used_amount: 'ご使用量',
    meter_current_month: '今回メーター指針',
    meter_previous_month: '前回メーター指針',
    meter_exchange_guideline: 'メーター交換日',
    old_meter_start_guidline: '旧メーター最終指針',
    new_meter_start_guidline: '新メーター開始指針',
    usage_period_days: '使用期間（日数）',
    usage_amount: '使用量',
    compare_gas_usage: 'LPガスの使用状況を比較する',
    compare_electric_usage: '電気の使用状況を比較する',
    monthly_usage: '月使用量',
    no_gas_usage: 'LPガスの使用はありません。',
    increase: '増加',
    decrease: '減少',
    this_month_from_last_year: '今月は前年より',
    usage_graph: '使用量グラフ',
    usage_last_year: '前年の使用量',
    usage_this_year: '今年の使用量',
    title_notification: 'お知らせ',
    unit_price: '円',
    usage_range: '使用量の幅',
    sub_total: '小計',
  },
  my_page: {
    my_page: 'マイページ',
    security_and_safety: '保安・安全',
    check_contract_information: '契約情報確認',
    profile_settings: 'ログイン情報変更',
    application_inquiry: '申込・問合せ',
  },
  security_safety: {
    inspection_date: '点検日',
    message_text: '点検の結果、改善（交換・修理等）が必要な項目を表示しております。',
    inspection_type: '点検種別',
    instrument_name: '点検項目',
    security_safety: '保安・安全',
    security_safety_title: '契約情報',
    contract_number: '契約番号：',
    household_gas: 'ご家庭のガス消費器具',
    emergency_contact_information: '緊急時連絡先（管轄営業所）',
    text_note_1: '緊急時以外で、ガスが出ない、ガス機器の不具合時の',
    text_note_2: '対応や災害時の対応については下記のサイトで',
    text_note_3: 'ご確認ください',
    please_check: '下記のサイトでご確認ください',
    results_of_periodic: '点検結果（改善が必要な器具一覧）',
    table: {
      no: 'No.',
      product_name: '商品名',
      sales_date: '売上年月日(設置日)',
      service_life: '耐用年数',
      quality: '数量',
      manufacturing_date: '製造年月',
      manufacture_name: 'メーカー名',
      modal: '型式',
      year: '年',
    },
    txt_no_emergency_info: '緊急時連絡先（管轄営業所）がありません。',
    txt_no_gas_equipment: 'ご家庭のガス消費器具 がありません。',
    collapse_2_note_1: '定期保安点検についての詳細は',
    collapse_2_note_2: 'のサイトでご紹介しております。',
  },
  profile_settings: {
    change_user_information: 'ユーザー情報変更',
    login_id: 'ログインID',
    email_address_change: '（メールアドレス）の変更',
    change_login_password: 'ログインパスワードの変更',
    change_notification_settings: '通知設定の変更',
    withdraw: '退会する',
    service_withdrawal_application: 'サービス退会申込',
  },
  inquiry_form: {
    application_inquiry_form: '申込・問合せフォーム',
    select_type: '種別を選択する',
    procedures_for_moving: '引っ越し手続き（引っ越し先でも継続利用する場合）',
    mitsubachi_gas_electricity: 'みつばちガス・電気の契約解除（閉栓）',
    contract_name_change: '契約者名義変更',
    payment_method_change: '支払い方法変更（クレジットカード変更）',
    change_payment_method: '支払い方法変更（引落口座変更）',
    gas_electricity_plan_change: 'ガス・電気のプラン変更（新規契約も含む）',
    withdraw_from_this_web_service: '本Webサービスを退会する',
    other_inquiries: 'その他お問合せ',
    content: '内容を入力する',
    check_your_entries: '入力内容を確認する',
    confirm_input_information: '入力情報を確認する',
    kinds: '種別',
    content_inputted: '入力内容',
    send_with_content: 'この内容で送信',
    thanks: 'ありがとうございます!',
    text_note: 'お申込み・お問合せありがとうございます',
    inquiry_form_content: '問い合わせ内容入力',
    contract_number: '検針票番号',
  },
  guide_pages: {
    faq: {
      about_app: 'みつばちアプリについて',
      about_services: '各種サービスについて',
      about_other_inquiries: 'その他お問い合わせ',
      contractor_question: 'ガスの契約者じゃない場合も使えますか？',
      usage_fee_question: '使用料金はかかりますか？',
      using_gas_question: 'みつばちガスのご利用に関してのご質問',
      using_electric_question: 'みつばち電気のご利用に関してのご質問',
      inquiries_web_form: 'WEBフォームからのお問い合わせ',
      contact_by_phone: '電話による合わせ',
    },
    usage_guide: {
      about_using_app: 'アプリの利用について',
      about_app_function: 'アプリの機能について',
      others: 'その他',
      about_operating_company: '運営会社について',
      first_time_register: '初回登録方法はこちら',
      login_instructions: 'ログイン方法はこちら',
      monthly_statement_check: '月々のご利用明細をいつでもチェック',
      other_charges_check: 'エネルギー以外の利用料金も確認できる',
      mitsuwa_industry_hp: '三ツ輪産業HP',
      enetwork_systems_hp: 'イーネットワークシステムズHP',
    },
    form_qa: {
      text_introduce: '※ 本WEBサイトに関するお問合せフォームです。',
      text_inquiries: 'ガスや電気の使用や料金に関するお問い合わせは',
      please_contact: 'に問い合わせてください。',
      here: 'こちら',
      sub_title: 'お問い合わせ内容を入力',
      sub_title_modal: 'お問い合わせフォーム',
      contract_number: '検針票番号（任意入力）',
      contact_brand: '問合せ先支店',
      requester_name: 'お名前（法人名）',
      requester_name_modal: 'お名前',
      email_address: 'メールアドレス ',
      phone_number: '電話番号 ',
      inquiry_contents: 'お問い合わせ内容 ',
      confirm_content_modal: 'お問い合わせ内容の確認',
      confirm_button: 'この内容で確定し送信する',
      thanks: 'お問合せありがとうございます ',
      please_enter: '( 入力してください )',
      text_note: 'お問合せありがとうございます',
    },
  },
  change_login_id: {
    title: 'ログインIDの変更',
    name: '認証コードを入力',
    current_login_id: '現在のログインID',
    after_change: '変更後のログインID',
    after_change_confirm: '変更後のログインID（確認用）',
    change: '変更',
    send_mail: 'ご入力いただいたメールアドレスに、本人確認のメールを\nお送りしました。',
    click_url: 'お送りしたメールに記載されているアドレス（URL）のクリックを',
    please: 'お願いいたします。',
    destination_email: '送信先メールアドレス : ',
    code: 'メールに記載された6桁の数字を入力してください。',
    placeholder_code: '6桁の数字を入力',
    go_to_top: '送信',
    placeholder_current_login_id: '現在のメールアドレス',
    placeholder_after_change: '変更後のメールアドレス',
    place_holder_login_id_confirm: '同じものをもう一度入力してください',
    modal_success: {
      title: 'ログインIDの変更',
      sub_title: 'ログインIDの変更が完了しました。',
      txt_email: 'ログインID（メールアドレス）: ',
      txt_button: '閉じる',
    },
  },
  sendMail: {
    title_reset_id: 'ログインIDの変更',
    message_reset_id: 'ログインIDの変更が完了しました。',
    login_id: 'ログインID（メールアドレス）:',
    normal_login_screen: 'ログイン画面へ',
    title_reset_password: 'ログインパスワードの変更',
    message_reset_password: 'ログインパスワードの変更',
  },
  resetPassword: {
    name: 'ログインパスワードの変更',
    current_password: '現在のログインパスワード',
    new_password: '変更後のログインパスワード',
    confirm_new_password: '変更後のログインパスワード（確認用）',
    change: '変更',
    confirm_text: 'パスワードを変更するため、ログアウトしてもよろしいですか？',
    cancel: 'キャンセル',
    logout: 'ログアウト',
    placeholder_current_password: '現在のログインパスワード',
    txt_change_password_success: 'パスワードの変更が完了しました。',
  },
  confirm_contract: {
    contract_information: '契約者情報',
    gas_contractor_information: 'みつばちガス契約情報',
    electricity_contractor_information: 'みつばち電気契約情報',
    contractor_name: '契約者名',
    contractor_name_kana: '契約者名（カナ）',
    phone_number: '電話番号 ',
    customer_number: '契約番号 ',
    contract_type: '契約区分',
    address: '住所',
    supply_point: '供給地点特定番号',
    start_date: '供給開始日',
    gas_contract_type: 'ガス契約プラン',
    by_contract: '契約中',
    end_contract: '契約終了',
    no_contract: '未契約',
    contract_company: '契約事業社',
    electricity_contract_type: '電気契約プラン',
    contract_capicity: '契約容量',
    corporation: '法人',
    individual: '個人',
    normal_contract: '通常契約',
    tier_five_contract: '5段階契約',
  },
  notification_settings: {
    for_generel: '一般のお知らせのメール通知 ',
    change: '変更',
    message_success: '通知設定の変更が完了しました。',
  },
  auth: {
    login: {
      normal_login: 'ログイン',
      save_login_email_address: 'ログイン用メールアドレスを保存する',
      login: 'ログイン',
      click_forgot_password: 'ログインパスワードを忘れた方は',
      or: 'または',
      click_initial_registation: '利用登録はこちら',
      honeybee_app: 'みつばちアプリとは？',
      here: 'こちら',
      news: 'お知らせ',
      text_time_update_notice: '更新',
      register_success_new_contract: '利用登録が完了しました。ログインしてください。',
    },
    register: {
      register_user: 'みつばちナビ利用登録',
      customer_id: '検針票番号（下4桁）',
      customer_id_placeholder: '検針票番号の下４桁',
      next: '次へ',
      check_customer_id: '検針票番号の確認方法はこちら',
      use_normal_login: 'ログイン画面',
      please_use: 'をお使いください',
      login_screen: 'へ進むをお使いください',
      proceed_to: 'へ進む',
      with_user: '利用登録済みの方は',
      register_email: 'みつばちナビ利用登録（メールアドレス登録）',
      after_register: 'みつばちナビログイン用のメールアドレスを登録します。',
      please_enter_to_send: '「送信」ボタンを押してください。',
      email: 'メールアドレス',
      confirm_email: 'メールアドレス（確認）',
      term_of_service: 'みつばちナビ利用規約',
      abolition_of_meter: '検針はがき等の発行停止について',
      term_of_use: '利用規約を確認しました',
      send: '送信',
      user_registration: 'メールアドレス登録完了',
      a_registration_email: 'ご登録のメールアドレスにメールを送信しました。',
      register_process: '※利用登録はまだ完了していません。',
      register_url_email: '入力いただいたメールアドレスにメールを送信しました。',
      continue_1: '※利用登録はまだ完了しておりません。',
      continue_2: '届きましたメールから、利用登録を完了させてください。',
      continue_3: 'メールに記載のURLの有効期限は2時間となります。',
      time_expired_1: '※メール内のURLの有効期限は2時間となりますので',
      time_expired_2: '早めにご確認ください。',
      register_qa: '問題が発生した場合やご質問がございましたら、',
      register_contract: '本画面上部の「申込・問合せ」からお問い合わせください。',
      to_inquiry_form: 'お問合せフォームへ',
      inquiri_about: 'ログインに関するお問合せは',
      step_1: '契約認証',
      step_2: '利用規約\n確認',
      step_3: 'メールアド\nレス入力',
      step_4: 'パスワード\n入力',
      step_5: '完了',
      check_terms_of_service: 'みつばちナビ利用規約',
      user_registration_terms: 'みつばちナビ利用登録（利用規約確認）',
      honeybee_navi_terms:
        '以下の「みつばちナビ利用規約」および「検針はがき等の発行停止について」をお読みいただき、同意のうえ、利用登録を続けてください。',
      user_registration_abolition: '検針はがき等の発行停止について',
      agree: '同意する',
      modal_noti_success: '新たな契約が登録しました。TOPページへ。',
      go_to_login: 'ログイン画面へ',
      go_to_top: 'TOPページへ',
      contract_code: '登録用番号',
      slide_text_link: {
        slide_1: {
          title: '検針票番号の確認方法',
          content_1: '「検針票」をお持ちのお客さま',
          content_2: '検針時にお配りしている『検針・請求書』の',
          content_red: '赤枠内',
          content_3: 'の番号(1桁-8桁)が検針票番号です。',
        },
        slide_2: {
          title: '検針票番号の確認方法',
          content_1: '「請求書」をお持ちのお客さま',
          content_2: '郵送にてお送りしている『請求書』の',
          content_red: '赤枠内',
          content_3: 'の番号(1桁-8桁)が検針票番号です。',
        },
        slide_3: {
          title: '検針票番号の確認方法',
          content_1: '「検針はがき」をお持ちのお客様',
          content_2: '郵送にてお送りしている『郵便はがき』の',
          content_red: '赤枠内',
          content_3: 'の番号(1桁-8桁)が検針票番号です。',
        },
      },
    },
    verify_email: {
      register_user: 'みつばちナビ利用登録',
      contract_information: '新たに登録する契約情報',
      password_registration: 'パスワード登録',
      successfully_registered: 'メールアドレスのご登録が完了しました。',
      please_register_your_password: ' パスワードを登録してください。',
      customer_id: ' お客様コード',
      address: ' 住所',
      send: ' 送信',
      register_contract_information: '登録済みの契約情報',
      login: 'ログイン',
      click_forgot_password: 'ログインパスワードを忘れた方は',
      here: 'こちら',
      account_login: 'アカウントログイン',
      email_enter: '入力いただいたメールアドレスはすでに登録されています。',
      please_enter: 'パスワードを入力しログインしてください。',
      registered_text_description: '入力いただいたメールアドレスはすでに登録されています。',
      enter_to_login: 'パスワードを入力しログインしてください。',
    },
    forgot_password: {
      time_expired: 'URLの有効期限は2時間となります。',
      reset_password: 'パスワード再設定',
      send_url: '再設定用URLを送信します。',
      text: 'パスワードを再設定します。新しいパスワードを入力して「送信」ボタンを押してください。',
      email: 'メールアドレス',
      send: '送信',
      new_password: '新たなパスワード',
      confirm_new_password: '新たなパスワード（確認用）',
      reset_success: 'パスワードの変更が完了しました。',
      user_registration: 'メール送信',
      a_registration_email_1: 'ご入力いただいたメールアドレスにパスワード再設定用の',
      a_registration_email_2: '案内メールをお送りしました。',
      continue_1: '案内メールのURLから、パスワード再設定の手続きを',
      continue_2: '完了させてください。',
      text_registration_send_mail_1:
        'ご登録のメールアドレスにパスワード再設定用URLをお送りします。',
      text_registration_send_mail_2: 'ご登録のメールアドレスを以下にご入力ください。',
      text_registration_send_mail_3: '「送信」ボタンを押してください。',
    },
    log_out: 'ログアウト',
    confirm_logout: 'みつばちナビからログアウトしてもよろしいですか？',
    cancel: 'キャンセル',
  },
  error: {
    url_expired: 'URLの有効期限が切れています。',
    please_msg: '最初からやり直してくだいさい。',
    not_exist: '指定されたページは存在しません。',
    no_noti_record: '通知がありません.',
    account_disabled: 'このアカウントは無効になっています。',
    no_data: 'データがありません。',
  },
  validate: {
    contact_branch_required: '問合せ先支店を選択してください。',
    required: 'を入力してください。',
    invalid_email: 'メールアドレスの形式が不正です。',
    email_not_match: 'メールアドレスとメールアドレス（確認用）が一致しません。',
    invalid_capcha: '４桁を入力してください。',
    current_login_id_invalid: '現在のログインIDを正しく入力してください。',
    required_terms_of_use:
      '利用規約を確認いただき、同意するチェックボックスにチェックを入れてください。',
    required_about_abolition:
      '検針ハガキの廃止についての同意するチェックボックスにチェックを入れてください。',
    password_not_match: 'パスワードとパスワード（確認用）が一致しません。',
    invalid_password: '半角英小文字・大文字・数字を含む8文字以上で入力してください。',
    invalid_phone_number_format: '電話番号の形式が不正です。',
    please_enter_new_email_different_current_enmail:
      '現在のログインIDと異なるメールアドレスを入力してください。',
    pleae_enter_new_password_different_current_password:
      '現在のログインパスワードと異なるパスワードを入力してください。',
    please_enter_phone_number_of_10_or_more: '電話番号は10桁以上で入力してください。',
    email_already_registered:
      'このメールアドレスは既に登録されているため、 別のアドレスを試してください。',
    please_enter_code_setting_email: 'メールに記載された6桁の数字を入力してください。',
  },
  notiDetail: {
    title: '通知詳細',
  },
  modal: {
    txt_500: 'エラーが発生しました。もう一度お試しください。',
    btn_cancel_500: '了解',
    btn_cancel: 'キャンセル',
    btn_ok: 'OK',
  },
}

export default ja
