import { put, takeLatest, all, call } from 'redux-saga/effects'
import {
  getContractDetailSuccessAction,
  getMeterDetailSuccessAction,
  getMonthContractSuccessAction,
} from 'src/redux/actions/monthContract'
import {
  ACTION_GET_METER_DETAIL,
  ACTION_GET_MONTH_CONTRACT,
  ACTION_GET_MONTH_CONTRACT_DETAIL,
} from 'src/redux/actions/monthContract/monthContractActions'
import instance from 'src/services/api'
import { Endpoint } from '../Endpoint'
import { generatePath } from 'react-router-dom'
import queryString from 'query-string'

function* getMonthContract(action) {
  try {
    const configRequest = () => {
      return instance.get(`${Endpoint.INVOICE_GET_BILL_AMOUNT}?${action.payload}`)
    }
    const response = yield call(configRequest)
    const newResult = {
      ...response?.data,
      param: queryString?.parse(action?.payload),
    }
    yield put(getMonthContractSuccessAction(newResult))
  } catch (error) {
    const status = error?.response?.data?.status
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getMonthContractSuccessAction({
        status: status,
        code: code,
        message: msg,
        param: queryString?.parse(action?.payload),
      }),
    )
  }
}

function* getContractDetail(action) {
  try {
    const configRequest = () => {
      return instance.get(`${Endpoint.FEE_DETAIL}?${action.payload}`)
    }
    const response = yield call(configRequest)
    yield put(getContractDetailSuccessAction(response?.data))
  } catch (error) {
    const status = error?.response?.data?.status
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getContractDetailSuccessAction({
        status: status,
        code: code,
        message: msg,
      }),
    )
  }
}

function* getMeterDetail(action) {
  try {
    const url = generatePath(Endpoint.METER_DETAIL, {
      contract_id: action?.payload,
    })
    const configRequest = () => {
      return instance.get(url)
    }
    const response = yield call(configRequest)
    yield put(getMeterDetailSuccessAction(response?.data))
  } catch (error) {
    const status = error?.response?.data?.status
    const msg = error?.response?.data?.error?.message
    const code = error?.response?.data?.error?.code
    yield put(
      getMeterDetailSuccessAction({
        status: status,
        code: code,
        message: msg,
      }),
    )
  }
}

export default function* getMonthContractSaga() {
  yield all([
    takeLatest(ACTION_GET_MONTH_CONTRACT, getMonthContract),
    takeLatest(ACTION_GET_MONTH_CONTRACT_DETAIL, getContractDetail),
    takeLatest(ACTION_GET_METER_DETAIL, getMeterDetail),
  ])
}
