import {
  ACTION_GET_METER_DETAIL,
  ACTION_GET_METER_DETAIL_SUCCESS,
  ACTION_GET_METER_SUMMARY_RESET,
  ACTION_GET_MONTH_CONTRACT,
  ACTION_GET_MONTH_CONTRACT_DETAIL,
  ACTION_GET_MONTH_CONTRACT_DETAIL_RESET,
  ACTION_GET_MONTH_CONTRACT_DETAIL_SUCCESS,
  ACTION_GET_MONTH_CONTRACT_RESET,
  ACTION_GET_MONTH_CONTRACT_SUCCESS,
} from './monthContractActions'

export const getMonthContractAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT,
    payload,
  }
}

export const getMonthContractSuccessAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT_SUCCESS,
    payload,
  }
}

export const resetGetMonthContractAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT_RESET,
    payload,
  }
}

export const getContractDetailAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT_DETAIL,
    payload,
  }
}

export const getContractDetailSuccessAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT_DETAIL_SUCCESS,
    payload,
  }
}

export const resetGetContractDetailAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT_DETAIL_RESET,
    payload,
  }
}

export const resetGetMeterSummaryAction = (payload) => {
  return {
    type: ACTION_GET_METER_SUMMARY_RESET,
    payload,
  }
}

export const getMeterDetailAction = (payload) => {
  return {
    type: ACTION_GET_METER_DETAIL,
    payload,
  }
}

export const getMeterDetailSuccessAction = (payload) => {
  return {
    type: ACTION_GET_METER_DETAIL_SUCCESS,
    payload,
  }
}
