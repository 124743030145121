import {
  ACTION_GET_METER_DETAIL,
  ACTION_GET_METER_DETAIL_SUCCESS,
  ACTION_GET_MONTH_CONTRACT,
  ACTION_GET_MONTH_CONTRACT_SUCCESS,
  ACTION_GET_MONTH_CONTRACT_RESET,
  ACTION_GET_MONTH_CONTRACT_DETAIL,
  ACTION_GET_MONTH_CONTRACT_DETAIL_SUCCESS,
  ACTION_GET_MONTH_CONTRACT_DETAIL_RESET,
  ACTION_GET_METER_SUMMARY_RESET,
  ACTION_GET_MONTH_BILL_DETAIL_SUCCESS,
  ACTION_GET_PDF_BILL_SUCCESS,
  ACTION_GET_MONTH_CONTRACT_ID_OLD_SUCCESS,
  ACTION_GET_MONTH_CONTRACT_ID_NEW_SUCCESS,
} from 'src/redux/actions/monthContract/monthContractActions'

const INITIAL_STATE = {
  monthBill: null,
  monthYearBill: null,
  monthYearNewBill: null,
  monthLatest: null,
  contractDetail: {},
  meterSummary: {},
  viewInvoice: {
    hidden_button: false,
    disabled_button: false,
  },
  pdfBlog: null,
  loading: false,
}

const monthContractReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_GET_MONTH_CONTRACT:
      return {
        ...state,
        loading: true,
      }
    case ACTION_GET_MONTH_CONTRACT_SUCCESS:
      if (action.payload?.param?.start_date === '') {
        return {
          ...state,
          monthBill: action.payload,
          monthLatest: action.payload?.results?.invoice_date,
          loading: false,
        }
      } else {
        return {
          ...state,
          monthBill: action.payload,
          loading: false,
        }
      }
    case ACTION_GET_MONTH_CONTRACT_ID_OLD_SUCCESS:
      return {
        ...state,
        monthYearBill: action.payload,
        loading: false,
      }
    case ACTION_GET_MONTH_CONTRACT_ID_NEW_SUCCESS:
      return {
        ...state,
        monthYearNewBill: action.payload,
        loading: false,
      }
    case ACTION_GET_MONTH_CONTRACT_RESET:
      return {
        ...state,
        monthBill: null,
        monthLatest: null,
        loading: false,
      }
    case ACTION_GET_MONTH_BILL_DETAIL_SUCCESS:
      return {
        ...state,
        viewInvoice: action.payload?.results,
        loading: false,
      }
    case ACTION_GET_PDF_BILL_SUCCESS:
      return {
        ...state,
        pdfBlog: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

const feeDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_GET_MONTH_CONTRACT_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case ACTION_GET_MONTH_CONTRACT_DETAIL_SUCCESS:
      return {
        ...state,
        contractDetail: action.payload,
        loading: false,
      }
    case ACTION_GET_MONTH_CONTRACT_DETAIL_RESET:
      return {
        ...state,
        contractDetail: {},
        loading: false,
      }
    default:
      return state
  }
}

const meterDetailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTION_GET_METER_SUMMARY_RESET:
      return {
        ...state,
        meterSummary: {},
        loading: false,
      }
    case ACTION_GET_METER_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case ACTION_GET_METER_DETAIL_SUCCESS:
      return {
        ...state,
        meterSummary: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export { monthContractReducer, feeDetailReducer, meterDetailReducer }
