import {
  ACTION_GET_METER_DETAIL,
  ACTION_GET_METER_DETAIL_SUCCESS,
  ACTION_GET_METER_SUMMARY_RESET,
  ACTION_GET_MONTH_CONTRACT,
  ACTION_GET_MONTH_CONTRACT_DETAIL,
  ACTION_GET_MONTH_CONTRACT_DETAIL_RESET,
  ACTION_GET_MONTH_CONTRACT_DETAIL_SUCCESS,
  ACTION_GET_MONTH_CONTRACT_RESET,
  ACTION_GET_MONTH_CONTRACT_SUCCESS,
  ACTION_GET_MONTH_CONTRACT_ID_OLD,
  ACTION_GET_MONTH_CONTRACT_ID_OLD_SUCCESS,
  ACTION_GET_MONTH_CONTRACT_ID_NEW,
  ACTION_GET_MONTH_CONTRACT_ID_NEW_SUCCESS,
  ACTION_GET_MONTH_BILL_DETAIL,
  ACTION_GET_MONTH_BILL_DETAIL_SUCCESS,
  ACTION_GET_PDF_BILL,
  ACTION_GET_PDF_BILL_SUCCESS,
} from './monthContractActions'

export const getMonthContractAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT,
    payload,
  }
}

export const getMonthContractSuccessAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT_SUCCESS,
    payload,
  }
}

export const getMonthContractIdOld = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT_ID_OLD,
    payload,
  }
}

export const getMonthContractIdNew = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT_ID_NEW,
    payload,
  }
}

export const getMonthContractIdSuccessAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT_ID_OLD_SUCCESS,
    payload,
  }
}

export const getMonthContractIdNewSuccessAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT_ID_NEW_SUCCESS,
    payload,
  }
}

export const resetGetMonthContractAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT_RESET,
    payload,
  }
}

export const getContractDetailAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT_DETAIL,
    payload,
  }
}

export const getContractDetailSuccessAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT_DETAIL_SUCCESS,
    payload,
  }
}

export const resetGetContractDetailAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_CONTRACT_DETAIL_RESET,
    payload,
  }
}

export const resetGetMeterSummaryAction = (payload) => {
  return {
    type: ACTION_GET_METER_SUMMARY_RESET,
    payload,
  }
}

export const getMeterDetailAction = (payload) => {
  return {
    type: ACTION_GET_METER_DETAIL,
    payload,
  }
}

export const getMeterDetailSuccessAction = (payload) => {
  return {
    type: ACTION_GET_METER_DETAIL_SUCCESS,
    payload,
  }
}

export const getMonthBillDetailAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_BILL_DETAIL,
    payload,
  }
}

export const getMonthBillDetailSuccessAction = (payload) => {
  return {
    type: ACTION_GET_MONTH_BILL_DETAIL_SUCCESS,
    payload,
  }
}

export const getPdfBillAction = (payload) => {
  return {
    type: ACTION_GET_PDF_BILL,
    payload,
  }
}

export const getPdfBillSuccessAction = (payload) => {
  return {
    type: ACTION_GET_PDF_BILL_SUCCESS,
    payload,
  }
}
